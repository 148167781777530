import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { Router, RouterEvent, NavigationEnd  } from "@angular/router";
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  analyticsEnabled = true;

  constructor(private router: Router) {
    this.router.events.pipe(
      filter((e: RouterEvent) => e instanceof NavigationEnd),
    ).subscribe((e: RouterEvent) => {

      this.setCurrentScreen(e.url)
    });

   }
  


    setUserId = async (id: string) => {
    await FirebaseAnalytics.setUserId({
      userId: id,
    });
  };
  
   setUserProperty = async (params: any) => {
    await FirebaseAnalytics.setUserProperty({
      key: params.key,
      value: params.value,
    });
  };
  
   setCurrentScreen = async (url:string) => {
    await FirebaseAnalytics.setCurrentScreen({
      screenName: url,
      screenClassOverride: url,
    });
  };
  
   logEvent = async (eventName: string, eventMethod: string) => {
    await FirebaseAnalytics.logEvent({
      name: eventName,
/*       params: { method: 'password' } */
      params: {method: eventMethod},
    });
  };
  
   setSessionTimeoutDuration = async () => {
    await FirebaseAnalytics.setSessionTimeoutDuration({
      duration: 120,
    });
  };
  
   setEnabled = async () => {
    await FirebaseAnalytics.setEnabled({
      enabled: true,
    });
  };
  
  isEnabled = async () => {
    const { enabled } = await FirebaseAnalytics.isEnabled();
    return enabled;
  };
  
   resetAnalyticsData = async () => {
    await FirebaseAnalytics.resetAnalyticsData();
  };


}
