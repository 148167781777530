import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from "@angular/router";
import { Storage } from "@ionic/storage";
import { DataService } from "../services/data.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private storage: Storage,
    public dataService: DataService
  ) {
    this.storage.create();


  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      this.storage.get("isUserLogged").then((isUserLogged: boolean) => {

        if (isUserLogged) {
  
          if(!this.dataService.getUserData().basic_profile_complete){

            this.router.navigate(["user-profile"]);

          }else{

            this.router.navigate(["menu/buscar"]);

          }


        }

        else {

          this.router.navigate([""]);


        }

      });
  
  
      return true;


  }
}
