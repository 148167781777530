import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Usuario } from '../models/Usuario';
import { Especialidad } from '../models/Especialidad';
import { Caracteristica } from '../models/Caracteristica';
import { Prestador } from '../models/Prestador';
import { Presupuesto } from '../models/Presupuesto';
import { Propuesta } from '../models/Propuesta';
import { PopoverController } from '@ionic/angular';
 
@Injectable({
  providedIn: 'root'
})
export class DataService {
  public Usuario: Usuario = {} as Usuario;
  public AT?: string;
  public LastestPresupuestoDetail: Presupuesto = {} as Presupuesto;
  public LastestPropuestaDetail: Propuesta = {} as Propuesta;

  public CurrentDate = new Date();
  public WelcomeMessage: string = '';
  public isUserLogged: boolean = false;
  public EspecialidadesPonderadas: Array<Especialidad> = [];
  public CaracteristicasPonderadas: Array<Caracteristica> = [];
  public CaracteristicaBusqueda: Caracteristica = {} as Caracteristica;
  public Prestador: Prestador = {} as Prestador;

  /* 0 Tab 1 */
  ProductTour: Array<boolean> = [false, false, false, false, false, false];

  isMobile: boolean;

  constructor(private storage: Storage, private popoverController: PopoverController) {
    this.storage.create();

    this.storage.get("ProductTour").then((ProductTour: Array<boolean>) => {

      if (!this.isVarNull(ProductTour)) {
        this.ProductTour = ProductTour;

      }

    });

  }

 

  setPlatform(isMobile: boolean) {
    this.isMobile = isMobile;


  }

  setLastPresupuestoData(LastestPresupuestoDetail: Presupuesto){
    this.LastestPresupuestoDetail = LastestPresupuestoDetail;

  }
  setLastPropuestaData(LastestPropuestaDetail: Propuesta){

    this.LastestPropuestaDetail = LastestPropuestaDetail;

  }

  getLastPropuestaData(){

    return this.LastestPropuestaDetail;
  }
  getLastPresupuestoData(){

    return this.LastestPresupuestoDetail;
  }


  getTourState(tour: number) {



    return this.ProductTour[tour];



  }

  setDefinedTour(tour: number, state: boolean) {

    this.storage.set('ProductTour', this.ProductTour);

    this.ProductTour[tour] = state;


  }

  definirNuevaLocalidad(DatosLocalidad: any, GuardarLocalidad: boolean) {


    this.Usuario.localidad = DatosLocalidad.nombre;
    this.Usuario.provincia = DatosLocalidad.provincia.nombre;
    this.Usuario.lat = DatosLocalidad.centroide.lat;
    this.Usuario.lng = DatosLocalidad.centroide.lon;

    if (this.Usuario.tiene_config_ambito) {

      this.Usuario.professional_profile.prestador_ubicacion_atencion[0].ubicacion_lat_domicilio = DatosLocalidad.centroide.lat;
      this.Usuario.professional_profile.prestador_ubicacion_atencion[0].ubicacion_lon_domicilio = DatosLocalidad.centroide.lon;
      this.Usuario.professional_profile.prestador_ubicacion_atencion[0].ubicacion_lat_provincia = DatosLocalidad.centroide.lat;
      this.Usuario.professional_profile.prestador_ubicacion_atencion[0].ubicacion_lon_provincia = DatosLocalidad.centroide.lon;
      this.Usuario.professional_profile.prestador_ubicacion_atencion[0].ubicacion_provincia = DatosLocalidad.provincia.nombre;
      this.Usuario.professional_profile.prestador_ubicacion_atencion[0].ubicacion_localidad = DatosLocalidad.nombre;

    }

    if (GuardarLocalidad) {

      this.storage.get("Usuario").then((userInfo) => {
        if (!this.isVarNull(userInfo)) {

          userInfo.localidad = DatosLocalidad.nombre;
          userInfo.provincia = DatosLocalidad.provincia.nombre;
          userInfo.lat = DatosLocalidad.centroide.lat;
          userInfo.lon = DatosLocalidad.centroide.lon;

          if (userInfo.tiene_config_ambito) {
            userInfo.professional_profile.prestador_ubicacion_atencion[0].ubicacion_lat_domicilio = DatosLocalidad.centroide.lat;
            userInfo.professional_profile.prestador_ubicacion_atencion[0].ubicacion_lon_domicilio = DatosLocalidad.centroide.lat;
            userInfo.professional_profile.prestador_ubicacion_atencion[0].ubicacion_lat_provincia = DatosLocalidad.centroide.lat;
            userInfo.professional_profile.prestador_ubicacion_atencion[0].ubicacion_lon_provincia = DatosLocalidad.centroide.lat;
            userInfo.professional_profile.prestador_ubicacion_atencion[0].ubicacion_provincia = DatosLocalidad.provincia.nombre;
            userInfo.professional_profile.prestador_ubicacion_atencion[0].ubicacion_localidad = DatosLocalidad.nombre;

          }
          this.storage.set('Usuario', userInfo);

        }



      });

    }


  }










  returnPlatform() {
    return this.isMobile;

  }

  setAccessToken(at: string){

    this.AT = at;
    this.storage.set('at', at);

  }

  getAccessToken(): string{

    return this.AT;

  }


  defineDataUser(Usuario: Usuario) {
    
    this.Usuario = Usuario;


  }

  setNewPrestadorProfile(Prestador: any) {

    this.storage.get("Usuario").then((userInfo) => {


      /*       this.storage.set('Usuario', userInfo);
       */




    });


  }

  defineUserPlan(Plan: any) {
    this.Usuario.professional_profile.prestador_plan = Plan;

  }

  defineIsLogged() {

    this.storage.get("isUserLogged").then((isUserLogged) => {

      if (this.isVarNull(isUserLogged)) {
        this.isUserLogged = false;

      } else {
        this.isUserLogged = isUserLogged;


      }

    });



  }



  setUserData(Usuario: Usuario) {


    this.storage.set('Usuario', Usuario);
    this.storage.set('isUserLogged', true);



    this.Usuario = Usuario;
    this.isUserLogged = true;

  }


  getUserData() {


    return this.Usuario;


  }

  getIsLogged() {


    return this.isUserLogged;



  }


  logout() {

    this.storage.set('isUserLogged', false);
    this.storage.clear();

  }







  isVarNull(variable: any) {

    if (variable == null || variable == undefined || variable == "null" || variable == "undefined") { return true } else { return false }

  }


  definirEspecialidadesPonderadas(EspecialidadesPonderadas: Array<Especialidad>) {
    this.EspecialidadesPonderadas = EspecialidadesPonderadas;

  }
  definirCaracteristicasPonderadas(CaracteristicasPonderadas: Array<Caracteristica>) {
    this.CaracteristicasPonderadas = CaracteristicasPonderadas;

  }

  definirCaracteristicaDeBusqueda(CaracteristicaDeBusqueda: Caracteristica) {
    this.CaracteristicaBusqueda = CaracteristicaDeBusqueda;
  }

  obtenerCaracteristicaDeBusqueda() {

    return this.CaracteristicaBusqueda;
  }

  obtenerEspecialidadesPonderadas() {

    return this.EspecialidadesPonderadas;
  }

  obtenerCaracteristicasPonderadas() {
    return this.CaracteristicasPonderadas;
  }

  getWelcomeMessage(): string {
    if (this.CurrentDate.getHours() > 5 && this.CurrentDate.getHours() < 12) {

      this.WelcomeMessage = 'Buen día, ' + this.getUserData().nombre_apellido;
    } else if (this.CurrentDate.getHours() >= 12 && this.CurrentDate.getHours() <= 18) {
      this.WelcomeMessage = 'Buenas tardes, ' + this.getUserData().nombre_apellido;

    } else if (this.CurrentDate.getHours() >= 12 && this.CurrentDate.getHours() <= 23) {
      this.WelcomeMessage = 'Buenas noches, ' + this.getUserData().nombre_apellido;

    } else if (this.CurrentDate.getHours() >= 0 && this.CurrentDate.getHours() <= 5) {
      this.WelcomeMessage = 'Buenas noches, ' + this.getUserData().nombre_apellido;


    }

    return this.WelcomeMessage;
  }




}
