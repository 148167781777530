import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { DataService } from "../app/services/data.service";
import { AutocloseOverlaysService } from "../app/services/autoclose-overlays.service";

import { Storage } from '@ionic/storage';
import { AnalyticsService } from './services/analytics.service';
import { ModalController } from '@ionic/angular';
import { Router, ActivatedRoute, NavigationStart } from "@angular/router";

import { register } from 'swiper/element/bundle';
register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  platformtype: boolean = false;

  constructor(private platform: Platform, private dataService: DataService, private storage: Storage,
    public dataServices: DataService,
    public analyticsService: AnalyticsService,
    public modalCtrl: ModalController,
    private router: Router,
    public autocloseOverlaysService:AutocloseOverlaysService
  ) {
    this.initializeApp();

/*
       
  this.router.events.subscribe((event: any): void => {
      if (event instanceof NavigationStart) {
        console.log('QHAY')

        if (event.navigationTrigger === 'popstate') {

          this.autocloseOverlaysService.trigger(this.router.url);

        }

      }
    }); */
  }

  initializeApp() {
    this.platform.ready().then(() => {


      this.platformtype = !this.platform.is('cordova');

      this.storage.get("Usuario").then((userInfo) => {
         if (!this.isVarNull(userInfo)) {
          this.dataServices.defineDataUser(userInfo);
          this.storage.get("at").then((at) => {
            this.dataServices.setAccessToken(at);

          });

          this.analyticsService.setEnabled();
          this.analyticsService.setUserId(userInfo.id);

          
        }



      });

    });


  };


  isVarNull(variable: any) {

    if (variable == null || variable == undefined || variable == "null" || variable == "undefined") { return true } else { return false }

  } 
}
 
