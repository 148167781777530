import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { DataService } from './data.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService {

  constructor(public dataServices: DataService, private router: Router,
    private toastController: ToastController
  ) { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  
    const token: string = this.dataServices.getAccessToken();

    let request = req;

    if (token) {
      request = req.clone({
        setHeaders: {
          authorization: `Bearer ${ token }`,
          platform: 'web',
          acept:'application/json'
        }
      });
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {

        if (err.status === 401) {
          this.dataServices.logout();
          this.router.navigateByUrl('/');
          this.presentToast('bottom');
    
        }

        return throwError( err );

      })
    );
  }

  async presentToast(position: 'top' | 'middle' | 'bottom') {
    const toast = await this.toastController.create({
      message: '¡Tu sesión ha expirado! Por favor, vuelve a iniciar sesión. ¡Gracias por tu comprensión y continuo apoyo!',
      duration: 1800,
      position: position,
    });

    await toast.present();
  }

}
