export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyA4OTNVi1P5IBbcWypMCn1YSR6j1BOpNL0",
    authDomain: "tienda-1c4a7.firebaseapp.com",
    projectId: "tienda-1c4a7",
    storageBucket: "tienda-1c4a7.appspot.com",
    messagingSenderId: "1021620850098",
    appId: "1:1021620850098:web:a9cbec42021e5420eee8ab",
    measurementId: "G-Y2JP33B75Q"
  }
};
