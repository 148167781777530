import { Injectable, ViewChildren, QueryList } from '@angular/core';
import { IonRouterOutlet, ActionSheetController, PopoverController, ModalController, MenuController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AutocloseOverlaysService {
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;

  constructor(    private modalCtrl: ModalController,private router: Router,
    
) { }

async trigger(url: string) {


  // close modal
  try {
    const element = await this.modalCtrl.getTop();

    if (element) {

      element.dismiss();
    
      return;
    }
  } catch (error) {

    
  }

}
}