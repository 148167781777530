import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../src/app/guards/auth.guard';

const routes: Routes = [
  {
    path: 'menu',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),


  },
  {
    path: '',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    canActivate: [AuthGuard],
  },
/*   {
    path: 'cambiar-localidad',
    loadChildren: () => import('./pages/location/location.module').then(m => m.LocationPageModule)
  }, */
  {
    path: 'resultado-prestadores',
    loadChildren: () => import('./pages/prestador_module/resultados-busqueda-prestador/resultados-busqueda-prestador.module').then(m => m.ResultadosBusquedaPrestadorPageModule)
  },
  {
    path: 'detail-prestador',
    loadChildren: () => import('./modals/prestador_module/detail-prestador/detail-prestador.module').then(m => m.DetailPrestadorPageModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./modals/map/map.module').then( m => m.MapPageModule)
  },
  {
    path: 'detalle-chat',
    loadChildren: () => import('./modals/prestador_module/detalle-chat/detalle-chat.module').then( m => m.DetalleChatPageModule)
  },
  {
    path: 'detalle-presupuesto',
    loadChildren: () => import('./modals/prestador_module/detalle-presupuesto/detalle-presupuesto.module').then( m => m.DetallePresupuestoPageModule)
  },
  {
    path: 'image-viewer',
    loadChildren: () => import('./modals/image-viewer/image-viewer.module').then( m => m.ImageViewerPageModule)
  },
  {
    path: 'refinar-busqueda',
    loadChildren: () => import('./pages/prestador_module/refinar-busqueda/refinar-busqueda.module').then( m => m.RefinarBusquedaPageModule)
  },
  {
    path: 'nuevo-presupuesto',
    loadChildren: () => import('./modals/prestador_module/nuevo-presupuesto/nuevo-presupuesto.module').then( m => m.NuevoPresupuestoPageModule)
  },
  {
    path: 'selector-especialidad',
    loadChildren: () => import('./modals/selector-especialidad/selector-especialidad.module').then( m => m.SelectorEspecialidadPageModule)
  },
  {
    path: 'auth-modal',
    loadChildren: () => import('./modals/auth-modal/auth-modal.module').then( m => m.AuthModalPageModule)
  },
  {
    path: 'perfil-usuario',
    loadChildren: () => import('./modals/perfil-usuario/perfil-usuario.module').then( m => m.PerfilUsuarioPageModule)
  },
  {
    path: 'comentarios',
    loadChildren: () => import('./modals/prestador_module/comentarios/comentarios.module').then( m => m.ComentariosPageModule)
  },
  {
    path: 'nuevo-comentario',
    loadChildren: () => import('./modals/nuevo-comentario/nuevo-comentario.module').then( m => m.NuevoComentarioPageModule)
  },
  {
    path: 'password-recovery',
    loadChildren: () => import('./modals/password-recovery/password-recovery.module').then( m => m.PasswordRecoveryPageModule)
  },
  {
    path: 'user-profile',
    loadChildren: () => import('./pages/user-profile/user-profile.module').then( m => m.UserProfilePageModule),
    canActivate: [AuthGuard],

  },
  {
    path: 'image-options',
    loadChildren: () => import('./modals/image-options/image-options.module').then( m => m.ImageOptionsPageModule)
  },
  {
    path: 'config-day',
    loadChildren: () => import('./modals/config-day/config-day.module').then( m => m.ConfigDayPageModule)
  },
  {
    path: 'ver-todos-solicitudes',
    loadChildren: () => import('./modals/ver-todos-solicitudes/ver-todos-solicitudes.module').then( m => m.VerTodosSolicitudesPageModule)
  },
  {
    path: 'nueva-propuesta',
    loadChildren: () => import('./modals/prestador_module/nueva-propuesta/nueva-propuesta.module').then( m => m.NuevaPropuestaPageModule)
  },
  {
    path: 'detalle-propuesta',
    loadChildren: () => import('./modals/prestador_module/detalle-propuesta/detalle-propuesta.module').then( m => m.DetallePropuestaPageModule)
  },
  {
    path: 'suscripcion',
    loadChildren: () => import('./modals/suscripcion/suscripcion.module').then( m => m.SuscripcionPageModule)
  },
  {
    path: 'change-location',
    loadChildren: () => import('./modals/change-location/change-location.module').then( m => m.ChangeLocationPageModule)
  },
  {
    path: 'profile-edition',
    loadChildren: () => import('./modals/profile-edition/profile-edition.module').then( m => m.ProfileEditionPageModule)
  },
  {
    path: 'new-event',
    loadChildren: () => import('./modals/new-event/new-event.module').then( m => m.NewEventPageModule)
  },
  {
    path: 'event-detail',
    loadChildren: () => import('./modals/event-detail/event-detail.module').then( m => m.EventDetailPageModule)
  },
  {
    path: 'config-payment-methods',
    loadChildren: () => import('./modals/config-payment-methods/config-payment-methods.module').then( m => m.ConfigPaymentMethodsPageModule)
  },
  {
    path: 'professional-more-info',
    loadChildren: () => import('./modals/professional-more-info/professional-more-info.module').then( m => m.ProfessionalMoreInfoPageModule)
  },
  {
    path: 'portal',
    loadChildren: () => import('./pages/portal-web/portal/portal.module').then( m => m.PortalPageModule)
  },
  {
    path: 'portal/detalle-opcion',
    loadChildren: () => import('./pages/portal-web/detalle-opcion/detalle-opcion.module').then( m => m.DetalleOpcionPageModule)
  },
  /* Rutas inexistentes */
  {path: '**', redirectTo: ''},
  {
    path: 'welcome',
    loadChildren: () => import('./pages/portal-web/modals/welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/portal-web/modals/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'values',
    loadChildren: () => import('./pages/portal-web/modals/values/values.module').then( m => m.ValuesPageModule)
  },

 












  /*   { path: '**', redirectTo: '' },
   */







];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
